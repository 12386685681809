import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    if (typeof google !== 'undefined') this.initMap()
  }

  initMap() {
    let map
    let bounds = new google.maps.LatLngBounds()
    let markers = []
    let startPosition = { lat: this.locations[0].lat, lng: this.locations[0].lng }

    map = new google.maps.Map(this.containerTarget, {
      center: startPosition,
      zoom: 10,
    })

    for (let location of this.locations) {
      let marker
      let position = { lat: location.lat, lng: location.lng }
      let contentString = this.generateContent(location)
      let infowindow = new google.maps.InfoWindow({
        content: contentString,
      })

      marker = new google.maps.Marker({
        position,
        map,
        title: location.title,
      })

      marker.addListener('click', function () {
        infowindow.open(map, marker)
      })

      markers.push(marker)
      bounds.extend(position)
      marker.setMap(map)
    }
    map.fitBounds(bounds)
    map.setZoom(5)
  }

  generateContent(location) {
    return `<div id='content' class='p-2 space-y-2'>
        <h1 class='font-semibold text-xl'>${location.title}</h1>
        <p>${location.address}</p>
        <a href='/projects/${location.slug}' class='inline-block underline hover:no-underline'>Go to project</a>
      </div>`
  }

  get locations() {
    return JSON.parse(this.data.get('locations'))
  }
}
