require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()

require.context('../images', true)

require('css/application.scss')
import 'typeface-montserrat'

import 'controllers'
import 'alpinejs'

// Makes the initMap function Google's API requires
// available in Stimulus controllers
window.initMap = function (...args) {
  const event = document.createEvent('Events')
  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}
